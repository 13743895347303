var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('form-wizard',{ref:"wizard",staticClass:"wizard-vertical mb-3",attrs:{"color":"#be63c7","title":null,"subtitle":null,"shape":"square","finish-button-text":"Registrar","back-button-text":"Atras","next-button-text":"Siguiente"},on:{"on-complete":_vm.validationTipoform}},[_c('b-overlay',{attrs:{"show":_vm.show,"rounded":"sm"}},[_c('tab-content',{attrs:{"title":"Información básica"}},[_c('validation-observer',[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Información básica")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Ingresa todos los campos, si tienes (*) Son obligatorios")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"* Tipo cliente","label-for":"v-tipo"}},[_c('validation-provider',{attrs:{"name":"tipo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.tipo_clientes,"label":"nombre","value":_vm.tipo_clientes.id,"clearable":false,"input-id":"tipo_cliente"},model:{value:(_vm.form.tipo_cliente),callback:function ($$v) {_vm.$set(_vm.form, "tipo_cliente", $$v)},expression:"form.tipo_cliente"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" campo requerido ")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"* Correo electronico","label-for":"v-correo"}},[_c('validation-provider',{attrs:{"name":"correo","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-correo","type":"email","placeholder":"Escribe tu correo electronico","state":errors.length > 0 ? false : null},model:{value:(_vm.form.main_mail),callback:function ($$v) {_vm.$set(_vm.form, "main_mail", $$v)},expression:"form.main_mail"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" campo requerido ")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"* Nombres","label-for":"v-nombre"}},[_c('validation-provider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-primer_nombre","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Escribe tu nombre"},model:{value:(_vm.form.nombre),callback:function ($$v) {_vm.$set(_vm.form, "nombre", $$v)},expression:"form.nombre"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" campo requerido ")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"*Apellidos","label-for":"v-apellido"}},[_c('validation-provider',{attrs:{"name":"apellido","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-apellido","type":"text","placeholder":"Escribe tu apellido","state":errors.length > 0 ? false : null},model:{value:(_vm.form.apellido),callback:function ($$v) {_vm.$set(_vm.form, "apellido", $$v)},expression:"form.apellido"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" campo requerido ")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"* Telefono Celular","label-for":"v-celular"}},[_c('validation-provider',{attrs:{"name":"celular|min:13","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-phone-number-input',{attrs:{"default-country-code":"CO","translations":{
                          countrySelectorLabel: 'Codigo pais',
                          countrySelectorError: 'Choisir un pays',
                          phoneNumberLabel: 'Numéro de Celular',
                          example: 'Ejemplo :',
                        }},on:{"update":function($event){_vm.results = $event}},model:{value:(_vm.form.main_cell_phone),callback:function ($$v) {_vm.$set(_vm.form, "main_cell_phone", $$v)},expression:"form.main_cell_phone"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" campo requerido ")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"* WhatsApp","label-for":"v-whatsapp"}},[_c('validation-provider',{attrs:{"name":"whatsapp|min:13","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('vue-phone-number-input',{attrs:{"default-country-code":"CO","translations":{
                          countrySelectorLabel: 'Codigo pais',
                          countrySelectorError: 'Choisir un pays',
                          phoneNumberLabel: 'Numéro de whatsapp',
                          example: 'Ejemplo :',
                        }},on:{"update":function($event){_vm.results = $event}},model:{value:(_vm.form.whatsapp),callback:function ($$v) {_vm.$set(_vm.form, "whatsapp", $$v)},expression:"form.whatsapp"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" campo requerido ")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"* Genero","label-for":"v-genero"}},[_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-radio',{staticClass:"custom-control-primary",attrs:{"name":"masculino","value":"2"},model:{value:(_vm.form.genero),callback:function ($$v) {_vm.$set(_vm.form, "genero", $$v)},expression:"form.genero"}},[_vm._v(" Masculino ")]),_c('b-form-radio',{staticClass:"custom-control-primary",attrs:{"name":"femenino","value":"1"},model:{value:(_vm.form.genero),callback:function ($$v) {_vm.$set(_vm.form, "genero", $$v)},expression:"form.genero"}},[_vm._v(" Femenino ")])],1)])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }